import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from '../Modal';
import ModalHeader from '../ModalHeader/ModalHeader';
import ModalBody from '../ModalBody/ModalBody';
import ModalFooter from '../ModalFooter/ModalFooter';
import CommonButton from '../../Buttons/CommonButton';

export default function ModalLeaveCampaign({
    cancelMessage,
    acceptMessage,
    acceptAction,
    bodyText,
    headerTitle,
    name,
    brand,
}) {
    const history = useHistory();
    const [showModalWarning, setShowModalWarning] = useState(false);
    const pendingPathRef = useRef('');


    useEffect(() => {
        const unblock = history.block((location) => {
            const path = location.pathname;
            if (path != '/' && path !== '/campaign/new' && !path.includes('/campaign/edit')) {
                if ((name.trim() === '' || brand.trim() === '') &&
                pendingPathRef.current == '') {
                    pendingPathRef.current = location.pathname;
                    setShowModalWarning(true);
                    return false;
                }
            } else {
                setShowModalWarning(false);
                pendingPathRef.current === '';
                return true;
            }
        });

        return () => {
            unblock();
        };
    }, [history, name, brand]);


    const handleCloseModal = () => {
        setShowModalWarning(false);
        pendingPathRef.current = '';
    };

    const handleCancelWarning = async () => {
        await acceptAction();
        setShowModalWarning(false);
        const unblock = history.block(() => true);
        history.push(pendingPathRef.current);
        pendingPathRef.current = '';
        unblock();
    };
    

    const handleAcceptWarning = () => {
        handleCloseModal();
    };

    return (
        <Modal size="sm" active={showModalWarning} toggler={handleCloseModal}>
            <ModalHeader toggler={handleCloseModal}>{headerTitle}</ModalHeader>
            <ModalBody>
                <p className="text-base leading-relaxed text-gray-600 font-normal">
                    {bodyText}
                </p>
            </ModalBody>
            <ModalFooter>
                <CommonButton
                    onClick={handleCancelWarning}
                    label={cancelMessage}
                    styleType={'danger'}
                    className="mx-2"
                />
                <CommonButton
                    onClick={handleAcceptWarning}
                    label={acceptMessage}
                    styleType={'primary'}
                />
            </ModalFooter>
        </Modal>
    );
}
