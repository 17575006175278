import React from 'react';
import CommonButton from '../../../../../Buttons/CommonButton';
import Modal from '../../../../../Modal/Modal';
import ModalBody from '../../../../../Modal/ModalBody/ModalBody';
import ModalFooter from '../../../../../Modal/ModalFooter/ModalFooter';
import ModalHeader from '../../../../../Modal/ModalHeader/ModalHeader';
import { useTranslation } from 'react-i18next';
import { useService } from '../../../../../../containers/UseService';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../../../../store';

const DeleteModal = (props) => {
    const dispatch = useDispatch();
    const { isActive, toggle, commercialPerson, onSubmit } = props;
    const { t } = useTranslation();
    const service = useService();
    const translations =
        'sections.management.section.company.companyCommercial.delete-modal.';

    const handleOnDelete = async () => {
        try {
            service.removeCommercialPersonToUser(commercialPerson.id);
            toggle();
            onSubmit();
            dispatch(
                notificationsActions.setNotification({
                    type: 'success',
                    title: t(translations + 'title'),
                    message: t(translations + 'deleted-alert'),
                })
            );
        } catch {
            dispatch(
                notificationsActions.setNotification({
                    type: 'warning',
                    title: t('common.notifications.error.title'),
                    message: t('common.notifications.error.errorData'),
                })
            );
        }
    };

    return (
        <Modal size="sm" active={isActive} toggler={toggle}>
            <ModalHeader toggler={toggle}>
                {t(translations + 'title')}
            </ModalHeader>
            <ModalBody>
                <div className="flex justify-between">
                    <p>
                        {t(translations + 'warning-message', {
                            name: commercialPerson?.name,
                        })}
                    </p>
                </div>
            </ModalBody>
            <ModalFooter>
                <CommonButton
                    onClick={handleOnDelete}
                    label={t(`common.buttons.remove`)}
                    styleType={'danger'}
                />
            </ModalFooter>
        </Modal>
    );
};

export default DeleteModal;
