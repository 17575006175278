import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../containers/UseAuth';
import { newCampaignActions } from '../../../../store';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import Hint from '../../../Tools/Hint';

const Naming = ({ name, brand, dispatch, isTest }) => {
    const { t } = useTranslation();

    const handleChangeName = (newValue) => {
        if (newValue.trim() === '' && name.trim() === '') {
            return false;
        }
        dispatch(newCampaignActions.setName(newValue));
    };

    const handleChangeBrand = (newBrand) => {
        if (newBrand.trim() === '' && brand.trim() === '')  {
            return false;
        }
        dispatch(newCampaignActions.setBrand(newBrand));
    };
    const auth = useAuth();

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.naming.title'
                    )}
                </div>
                <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                    {name === ''
                        ? t(
                              'sections.campaigns.new-campaign.wizard-basics.naming.campaignName-label'
                          )
                        : name}{' '}
                    -{' '}
                    {brand === ''
                        ? t(
                              'sections.campaigns.new-campaign.wizard-basics.naming.brand-label'
                          )
                        : brand}
                </div>
            </div>

            <div className="body rounded-sm p-1">
                {(auth.isAdmin() || auth.isSupervising()) && (
                    <div className="w-28 ml-auto absolute right-4 -top-1 ">
                        <div className="realative">
                            <SwitcherButton
                                status={isTest}
                                onToggle={() =>
                                    dispatch(
                                        newCampaignActions.setIsTest(!isTest)
                                    )
                                }
                                labelOn={t(
                                    'sections.campaigns.new-campaign.wizard-basics.naming.testing'
                                )}
                                labelOff={t(
                                    'sections.campaigns.new-campaign.wizard-basics.naming.testing'
                                )}
                                hintTitle={t(
                                    'sections.campaigns.new-campaign.wizard-basics.naming.testing'
                                )}
                                hintMessage={t(
                                    'sections.campaigns.new-campaign.wizard-basics.naming.hintTesting'
                                )}
                                dataFor="testint-switcher-hint"
                            />
                        </div>
                    </div>
                )}
                <label className="block md:w-3/6 mb-4">
                    <span className="block text-sm font-medium text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.naming.campaignName-label'
                        )}
                    </span>
                    <input
                        type="text"
                        value={name}
                        name="campaign-name"
                        onChange={(e) => handleChangeName(e.target.value)}
                        id="campaign-name"
                        className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t(
                            'sections.campaigns.new-campaign.wizard-basics.naming.campaignName-placeholder'
                        )}
                    />
                </label>
                <label className="block md:w-3/6 mb-4">
                    <span className="block text-sm font-medium text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.naming.brand-label'
                        )}
                    </span>
                    <input
                        type="text"
                        value={brand}
                        name="campaign-brand"
                        onChange={(e) => handleChangeBrand(e.target.value)}
                        id="campaign-brand"
                        className="focus:ring-0 focus:outline-none block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        placeholder={t(
                            'sections.campaigns.new-campaign.wizard-basics.naming.brand-placeholder'
                        )}
                    />
                </label>
            </div>
        </div>
    );
};

export default Naming;
