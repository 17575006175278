import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { newCampaignActions } from '../../../store';

function WizardLaunch({ service, isActive, newCampaign }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const step = newCampaign.stepper.wizard;
    const wasLaunched = newCampaign.wasLaunched;
    const isResizeMapOn = newCampaign.isResizeMapOn;
    const selectedScreens = newCampaign.selectedScreens;
    const loading = (
        <FontAwesomeIcon
            icon={['fas', 'circle-notch']}
            className="text-blue-400 animate-spin"
        />
    );
    const checkSquare = (
        <FontAwesomeIcon
            icon={['fas', 'check-square']}
            className="text-green-300"
        />
    );

    useEffect(() => {
        const handleLaunch = async () => {
            if (!wasLaunched && step === 'launch') {
                dispatch(newCampaignActions.setIsLaunching(true));
                let isLaunch = await service.launchCampaign();

                if (!isLaunch) {
                    dispatch(newCampaignActions.setCanLaunch(false));
                    dispatch(
                        newCampaignActions.setStepper({
                            index: 5,
                            wizard: 'review',
                        })
                    );
                    dispatch(newCampaignActions.setCurrentWizard('review'));
                }

                dispatch(newCampaignActions.setIsLaunching(false));
                history.push('/');
            }
        };

        handleLaunch();
    }, [step]);

    return (
        <>
            {!isActive ? (
                ''
            ) : (
                <div
                    className={`launch mt-2 h-full overflow-visible overflow-y-auto text-sm transition-transform duration-299 ${
                        isResizeMapOn
                            ? 'transform scale-75 -translate-y-20 -mr-16 -ml-16'
                            : ''
                    }`}>
                    <div className="module rounded m-1 mb-4">
                        <div className="header flex justify-between content-center">
                            <div className="title text-xl font-light">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-launch.launch.title'
                                )}
                            </div>
                            {/*Cuando tengamos el estado de cada pantalla mostramos este lavel
                         <div className="brief font-light text-xs text-gray-400 mt-1.5 text-right">
                            {t('sections.campaigns.new-campaign.wizard-launch.launch.info')}
                        </div> */}
                        </div>
                        <div className="body rounded-sm p-1 divide-y divide-dotted">
                            <ul className="font-light text-xl text-gray-500">
                                {/* Procesos de fondo:
                                - Se genera la campaña en Json.
                                - Se chequean que las pantallas seleccionadas estén online.
                                - Se les envía un mail a cada responsable de pantalla que una nueva campaña está a punto de ejecutarse y necesita una pronta aprobación.
                                    - 1) Si las pantallas son del Digital Signage de Taggify, el Pantallero tendrá un botón para Aceptar o Rechazar una campaña.
                                        - A medida que se aprueben las campañas se reflejará en esta pantalla.
                                    - 2) Si las pantallas no son del Digital Signage de Taggify, simplemente avisaremos que una pantalla está por empezar, si no tiene objeción, se iniciará la misma a la brevedad.
                                - Al superar el 30% de aceptación, pasaremos a la activación de la campaña Y mandaremos el status de "Campaña Activada" donde daremos acceso al dashboard.
                            */}
                                <li>
                                    {wasLaunched ? checkSquare : loading}{' '}
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-launch.launch.creating-campaign'
                                    )}
                                </li>
                                {/* <li>{wasLaunched ? checkSquare : loading} {t('sections.campaigns.new-campaign.wizard-launch.launch.checking-screens')}</li>
                            <li>{wasLaunched ? checkSquare : loading} {t('sections.campaigns.new-campaign.wizard-launch.launch.sending-requests')} (45 {t('common.words.of')} {selectedScreens.length})</li>
                            <li>{loading} <span className="text-black">21</span> {t('common.words.of')} 45 {t('sections.campaigns.new-campaign.wizard-launch.launch.campaign-lauched-message-remaing')}</li>*/}
                                <li
                                    className={`mt-4 text-black ${
                                        !wasLaunched ? 'hidden' : ''
                                    }`}>
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-launch.launch.campaign-launched-message'
                                    )}{' '}
                                    <a href="" className="link">
                                        {t('common.menu.dashboard')}.
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

WizardLaunch.proptyTypes = {
    service: PropTypes.func,
    isActive: PropTypes.bool.isRequired,
    newCampaign: PropTypes.object,
};

export default WizardLaunch;
