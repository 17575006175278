import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import 'rc-slider/assets/index.css';
import Header from '../../components/Header/Header';
import { useParams } from 'react-router-dom';
import { useService } from '../UseService';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import {
    capitalizeFirstLetter,
    formatDateAccordingToBrowser,
    isByHourCurrentPeriod,
    isPeriodSelectedInRangeOfCampaign,
    numberWithoutCommas,
    formatNumber,
    dateToInt,
} from '../../utils/functions';
import CampaignBrief from '../../components/DashboardIndividual/CampaignBrief/CampaignBrief';
import DropdownPeriods from '../../components/Select/DropdownPeriods';
import QuickControls from '../../components/DashboardIndividual/QuickControls/QuickControls';
import Predictions from '../../components/DashboardIndividual/Predictions/Predictions';
import ReportsTable from '../../components/Dashboard/ReportsTable/ReportsTable';
import DashboardStats from '../../components/DashboardIndividual/DashboardStats/DashboardStats';
import { dashboardActions, notificationsActions } from '../../store';
import { useLocalStorage } from '../../hooks/storage/useLocalStorage';
import statsService from '../../services/statsService';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PredictionDetails from '../../components/DashboardIndividual/Predictions/PredictionDetails/PredictionDetails';
import { useAuth } from '../UseAuth';

function DashboardPageIndividual() {
    const { t } = useTranslation();
    const service = useService();
    const dispatch = useDispatch();
    const { id } = useParams();
    const currencies = useSelector((state) => state.wallet.currencies);
    const dashboard = useSelector((state) => state.dashboard);
    const campaignPrediction = dashboard.campaignPrediction;
    const timezones = useSelector((state) => state.extra.timezones);
    const isCampaignPrediction = !!dashboard.campaignPrediction.screensDetails;
    const [currentPeriod, setCurrentPeriod] = useLocalStorage('currentPeriod', [
        null,
        null,
    ]);
    const individualCampaignStats = dashboard.individualCampaignStats;
    const campaign = dashboard.campaign;
    const isValidPrediction = JSON.stringify(campaignPrediction) !== '{}';
    const isActive = campaign.status === 5;
    const hasPredictions = isActive && isValidPrediction;
    const [isLoading, setIsLoading] = useState(dashboard.isLoading);
    const [reportsTable, setReportsTable] = useState([]);
    const [timezone, setTimezone] = useState({});
    const [graphicReport, setGraphicReport] = useState({});
    const [graphicOptions, setGraphicOptions] = useState({});
    const isDailyCapByBudget = !!campaign?.limit_type;
    const fadeOutAnimation = `transition-opacity delay-300 ${
        isLoading ? 'opacity-0' : 'opacity-1'
    }`;
    const [value, setValue] = useLocalStorage('dateName', 'today');
    const [totalRows, setTotalRows] = useState([]);
    const [campaignScreensStatus, setCampaignScreensStatus] = useState({});
    const [handleDataSchedule, setHandleDataSchedule] = useState(true);
    const isDelayed = campaignPrediction.endDate !== 'On time';
    const [showReport, setShowReport] = useState(true);
    const auth = useAuth();

    const [seeImpressions, setSeeImpressions] = useState(false);
    useEffect(() => {
        let adminOrSupervising = auth.isAdmin() || auth.isSupervising();
        setSeeImpressions(adminOrSupervising);
    }, []);

    const hasPendingOrRejected =
        campaignScreensStatus?.status?.pendingScreens?.total > 0 ||
        campaignScreensStatus?.status?.disapprovedScreens?.total > 0;

    useEffect(() => {
        if (id && !campaign.id && currencies.length > 0) {
            service.getCampaignPreview(id);
        }
    }, [id, campaign, currencies]);

    useEffect(() => {
        return () => {
            dispatch(dashboardActions.reset());
        };
    }, []);

    useEffect(() => {
        if (
            campaign.timezone_id &&
            timezones.length > 0 &&
            Object.keys(timezone).length === 0
        ) {
            let timezoneFound = timezones.find(
                (timezone) => timezone.id === campaign.timezone_id
            );
            setTimezone(timezoneFound);
        }
    }, [timezones, campaign]);

    useEffect(() => {
        const isOnRange = isPeriodSelectedInRangeOfCampaign(
            currentPeriod,
            campaign
        );
        if (campaign.id && currentPeriod[0] && currentPeriod[1] && isOnRange) {
            service.getCampaignStats(campaign, currentPeriod).then((res) => {
                const { totals, newStats } = res;
                setTotalRows(totals);
            });
            setIsLoading(false);
            setShowReport(true);
        } else {
            if (
                campaign.start_period &&
                campaign.end_period &&
                currentPeriod[0] &&
                currentPeriod[1] &&
                !isOnRange
            ) {
                dispatch(dashboardActions.removeIndividualCampaignStats());
                setShowReport(false);
                setGraphicReport({});
                const errorMessage = `${t(
                    'sections.dashboard-individual.period-out-of-range-description'
                )}, ${t(
                    'common.components.DateRangePicker.from'
                )}: ${formatDateAccordingToBrowser(campaign.start_period)} ${t(
                    'common.components.DateRangePicker.until'
                )}: ${formatDateAccordingToBrowser(campaign.end_period)}`;
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.dashboard-individual.period-out-of-range-title'
                        ),
                        message: errorMessage,
                    })
                );
            }
        }
    }, [timezone, currentPeriod]);

    // Update campaign on background every 2 minutes
    useEffect(() => {
        const cron = setInterval(async () => {
            // Only update if now is between start and end period
            if (
                dateToInt(currentPeriod[0]) <= dateToInt(Date.now()) &&
                dateToInt(currentPeriod[1]) >= dateToInt(Date.now())
            ) {
                setIsLoading(false);
                const isOnRange = isPeriodSelectedInRangeOfCampaign(
                    currentPeriod,
                    campaign
                );
                if (
                    campaign.id &&
                    currentPeriod[0] &&
                    currentPeriod[1] &&
                    isOnRange
                ) {
                    dispatch(dashboardActions.setIsSilentLoading(true));
                    await service.getCampaignStats(campaign, currentPeriod);
                    dispatch(dashboardActions.setIsSilentLoading(false));
                }
            }
        }, 120_000);
        return () => {
            clearInterval(cron);
        };
    }, [currentPeriod]);

    function filterOutRowsWithoutProperty(
        dataArray,
        primaryProperty,
        secondaryProperty
    ) {
        return dataArray.map((report) => {
            const filteredData = report.data.filter((item) => {
                if (!item[primaryProperty]) return false;

                if (
                    item[secondaryProperty] <= 0 ||
                    item[secondaryProperty] === '-'
                )
                    return false;

                return true;
            });
            return { ...report, data: filteredData };
        });
    }

    useEffect(() => {
        if (
            individualCampaignStats.impressions !== '-' &&
            Object.keys(campaign).length > 0
        ) {
            const { graphic, options } = formatGraphicReport(
                individualCampaignStats.days,
                individualCampaignStats.hours
            );
            setGraphicReport(graphic);
            setGraphicOptions(options);
            const reportsTable = formatTableReport();
            if (reportsTable[0].data) {
                setReportsTable(
                    filterOutRowsWithoutProperty(
                        reportsTable,
                        'impressions',
                        'impacts'
                    )
                );
            } else {
                setReportsTable([]);
            }
        }
    }, [individualCampaignStats, campaign]);

    const formatGraphicReport = (reportsByDay, reportsByHour) => {
        let subfijo = 'h';
        let labels = [];
        let datasets = [];
        let yAxes = [];

        let reports = [];
        const byHours = isByHourCurrentPeriod(currentPeriod);
        if (byHours) reports = reportsByHour;
        else reports = reportsByDay;

        let reportsCopy;
        if (reports) {
            reportsCopy =
                reports.length > 1 ? [...reports] : [...reports, ...reports];
        }

        // add format to labels of timeline
        const tooltips = {
            callbacks: {
                label: function (tooltipItems, data) {
                    const translatedLabel =
                        t('common.words.spent').toLowerCase();
                    const index = tooltipItems.datasetIndex;
                    const label = data.datasets[index].label;
                    let value = data.datasets[index].data[tooltipItems.index];
                    let withSymbol =
                        label.toLowerCase() == translatedLabel
                            ? campaign.currency.symbol
                            : false;
                    if (withSymbol) {
                        value = formatNumber(value, 'currency');
                    } else {
                        value = formatNumber(value, 'rounded');
                    }
                    return `${label}: ${withSymbol || ''}${value}`;
                },
            },
        };
        if (reportsCopy) {
            reportsCopy.forEach((reprt) => {
                if (byHours) {
                    labels.push(`${reprt.hour}${subfijo}`);
                } else {
                    moment.locale(window.navigator.language);
                    let dateWithMonth = moment(reprt.date, 'YYMMDDHH').format(
                        'L'
                    );
                    dateWithMonth = dateWithMonth.substring(0, 5);
                    labels.push(dateWithMonth);
                }

                Object.keys(reprt).forEach((key, index) => {
                    const translatedLabel = t(
                        'common.words.' + key
                    ).toLowerCase();
                    let dataset = datasets.find(
                        (set) => 'label' in set && set.label === translatedLabel
                    );
                    const keyIsImpressions = key === 'impressions';
                    const keyIsSpent = key === 'spent';
                    const keyIsImpacts = key === 'impacts';
                    let dataWithFormat;
                    switch (key) {
                        case 'impacts':
                            dataWithFormat = parseInt(reprt[key]);
                            break;
                        case 'spent':
                            dataWithFormat = parseFloat(reprt[key]).toFixed(2);
                            break;
                        default:
                            dataWithFormat = reprt[key];
                            break;
                    }

                    if (dataset) {
                        dataset.data = [...dataset.data, dataWithFormat];
                    } else {
                        if (
                            (isDailyCapByBudget && keyIsSpent) ||
                            (!isDailyCapByBudget && keyIsImpressions) ||
                            keyIsImpacts
                        ) {
                            datasets.push({
                                label: translatedLabel,
                                data: [dataWithFormat],
                                fill: keyIsImpacts,
                                backgroundColor: keyIsImpacts
                                    ? 'rgba(54, 162, 235, 0.3)'
                                    : 'rgb(255, 99, 132)',
                                borderColor: keyIsImpacts
                                    ? 'rgba(54, 162, 235, 0.2)'
                                    : 'rgba(255, 99, 132, 0.7)',
                                yAxisID: `y-axis-${index}`,
                            });
                            yAxes.push({
                                type: 'linear',
                                display: true,
                                position: keyIsImpacts ? 'right' : 'left',
                                id: `y-axis-${index}`,
                                gridLines: {
                                    display: keyIsImpacts,
                                },
                                ticks:
                                    key === 'spent'
                                        ? {
                                              beginAtZero: true,
                                              callback: function (value) {
                                                  if (
                                                      dashboard
                                                          ?.selectedCurrency
                                                          ?.short_name
                                                  ) {
                                                      value = formatNumber(
                                                          value,
                                                          'properCurrency',
                                                          null,
                                                          {
                                                              currency:
                                                                  dashboard
                                                                      .selectedCurrency
                                                                      ?.short_name,
                                                          }
                                                      );
                                                      value =
                                                          value.split(',')[0];
                                                  }
                                                  return value;
                                              },
                                          }
                                        : {
                                              beginAtZero: true,
                                          },
                            });
                        }
                    }
                });
            });
        }
        return {
            //if the campaign hasn't had any impacts, only use the spent.
            graphic: {
                labels,
                datasets: dashboard.individualCampaignStats.impacts
                    ? datasets
                    : datasets.length
                    ? [datasets[0]]
                    : [],
            },
            options: { maintainAspectRatio: true, scales: { yAxes }, tooltips },
        };
    };

    const formatTableReport = () => {
        const reportsTable = JSON.parse(
            JSON.stringify(individualCampaignStats)
        );
        const formatedTable = [
            {
                tabName: t('common.words.screens'),
                columnName: t('common.words.screen'),
                data: reportsTable.screen?.map((row) => ({
                    ...row,
                    name: row.screen,
                    currency: campaign.currency,
                })),
            },
            {
                tabName: capitalizeFirstLetter(t('common.words.days')),
                columnName: capitalizeFirstLetter(t('common.words.day')),
                data: reportsTable.days?.map((row) => {
                    moment.locale(window.navigator.language);
                    let dateWithMonth = moment(row.date, 'YYMMDDHH').format(
                        'L'
                    );
                    dateWithMonth = dateWithMonth.substring(0, 5);
                    return {
                        ...row,
                        name: dateWithMonth,
                        currency: campaign.currency,
                    };
                }),
            },
            {
                tabName: t('common.words.hours'),
                columnName: t('common.words.hour'),
                data: reportsTable.hours?.map((row) => ({
                    ...row,
                    name: row.hour,
                    currency: campaign.currency,
                })),
            },
        ];

        return formatedTable;
    };

    const [screenSizeMatches, setScreenSizeMatches] = useState(
        window.matchMedia('(min-width: 768px)').matches
    );

    useEffect(() => {
        window
            .matchMedia('(min-width: 768px)')
            .addEventListener('change', (e) => setScreenSizeMatches(e.matches));
    }, []);

    useEffect(() => {
        statsService
            .getCampaignsScreensStatus(id)
            .then((res) => {
                setCampaignScreensStatus(res.message[0]);
            })
            .catch((err) => {
                setCampaignScreensStatus({ error: true });
            });
    }, []);

    return !campaign.id ? (
        <Loading isLoading={true}></Loading>
    ) : (
        <>
            <Helmet>
                <title>{`${t('sections.dashboard.page-title')} | ${
                    campaign.id
                } - ${
                    campaign.brand
                        ? campaign.brand
                        : t('sections.campaign-list.without-brand')
                }`}</title>
            </Helmet>
            <div className="fixed h-screen w-full bg-gray-100 overflow-y-auto pb-10 mt-8 md:mt-0">
                <Header
                    currentSection={t('sections.dashboard.page-title')}
                    sectionPath={'/dashboard'}
                    subSection={`${
                        campaign.brand ? campaign.brand + ' | ' : ''
                    }${campaign.name ? campaign.name : ''}`}
                    campaignId={campaign.id}
                    extraIcon={
                        hasPredictions ? (
                            <FontAwesomeIcon
                                onClick={() => {
                                    setHandleDataSchedule(!handleDataSchedule);
                                }}
                                icon={[
                                    'fad',
                                    `${
                                        hasPendingOrRejected
                                            ? 'exclamation-triangle'
                                            : 'info-circle'
                                    }`,
                                ]}
                                fixedWidth
                                className={`mr-1 ${
                                    hasPendingOrRejected && 'text-yellow-500'
                                } cursor-pointer`}
                            />
                        ) : null
                    }
                />
                <Loading isLoading={isLoading} />
                <DropdownPeriods
                    isDisabled={dashboard.isLoading}
                    fromWholePeriod={campaign.start_period}
                    toWholePeriod={campaign.end_period}
                    handleDropdownPeriods={'DashboardPageIndividual'}
                    currentPeriod={currentPeriod}
                    setCurrentPeriod={setCurrentPeriod}
                    value={value}
                    setValue={setValue}
                    loadingIcon={true}
                />
                {/* Nueva Estructura grid grid-rows-4 md:grid-rows-3 grid-cols-2 md:grid-cols-6 grid-flow-row auto-cols-max gap-4 */}
                <div className={`workingArea mt-4 ${fadeOutAnimation}`}>
                    <div className="flex w-full flex-col md:flex-row my-2 space-y-2 md:space-y-0 md:space-x-2">
                        <DashboardStats
                            currentPeriod={currentPeriod}
                            seeImpressions={seeImpressions}
                        />
                    </div>
                    <div className="row flex flex-col md:flex-row my-2 space-y-2 md:space-y-0 md:space-x-2">
                        <div className="md:flex-1 module items-center relative overflow-x-auto">
                            <QuickControls />
                        </div>
                        {hasPredictions && (
                            <div className="module flex-auto">
                                <Predictions
                                    campaignScreensStatus={
                                        campaignScreensStatus
                                    }
                                />
                                <div className="flex flex-col h-full font-light text-xs uppercase">
                                    <PredictionDetails
                                        setHandleDataSchedule={
                                            setHandleDataSchedule
                                        }
                                        handleDataSchedule={handleDataSchedule}
                                        isCampaignPrediction={
                                            isCampaignPrediction
                                        }
                                        campaignPrediction={campaignPrediction}
                                        campaign={campaign}
                                        isDelayed={isDelayed}
                                        campaignScreensStatus={
                                            campaignScreensStatus
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <div className="module flex-auto">
                            <CampaignBrief timezone={timezone} />
                        </div>
                    </div>
                    <div
                        className={`${
                            dashboard.isSilentLoading || dashboard.isLoading
                                ? 'opacity-50'
                                : 'row flex flex-col md:flex-row my-2 space-y-2 md:space-y-0 md:space-x-2'
                        }`}>
                        <div className="module w-full md:flex-auto">
                            <div className="font-light text-xs uppercase">
                                {t('sections.dashboard.timeline')}
                            </div>
                            {'datasets' in graphicReport &&
                                'scales' in graphicOptions && (
                                    <Line
                                        width={98}
                                        height={screenSizeMatches ? 30 : 70}
                                        fill={true}
                                        data={graphicReport}
                                        options={graphicOptions}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {showReport && (
                    <div className={`workingArea mt-2 ${fadeOutAnimation}`}>
                        <ReportsTable
                            withGraph
                            rowTotals={totalRows}
                            data={reportsTable}
                            currency={campaign.currency}
                            campaignName={campaign.name}
                            seeImpressions={seeImpressions}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default DashboardPageIndividual;
